@tailwind base;

@layer base {
  path,
  circle,
  ellipse,
  line,
  polygon,
  polyline,
  rect {
    stroke-width: inherit;
  }
}

@tailwind components;
@tailwind utilities;

body {
  @apply font-open-sans antialiased bg-gray-200 text-gray-800;
}

#root {
  max-width: 100%;
  min-height: 100vh;
  @apply bg-gray-200;
}
